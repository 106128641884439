import dynamic from "next/dynamic";

// import { LoginView } from "@modules/login";

export default dynamic(
    () => import("@modules/login").then((module) => module.LoginView),
    {
        ssr: false,
    },
);
